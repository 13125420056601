.theme-red {
  --main-950: #490706;
  --main-900: #861816;
  --main-800: #A21412;
  --main-700: #C41411;
  --main-600: #E91C19;
  --main-500: #FB3B38;
  --main-400: #FF6967;
  --main-300: #FFA09F;
  --main-200: #FFC7C6;
  --main-100: #FFE0E0;
  --main-50: #FFF1F1;
}
